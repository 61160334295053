<template>
  <div class="row mt-4" v-if="showOrgs">
    <hr/>
    <div class="card" style="border: 3px solid green;">
      <!--      {{ keepingOrgDisplay }}-->
      <div class="card-header">
        <h3 style="color: black">Keeping</h3>
      </div>
      <div class="card-body ">
        <!--        <h4>{{ keepingOrgDisplay.organizationName.value }}</h4>-->
        <MergeObjectDisplay :display-data="keepingOrgDisplay"
                            @valChanged="updateSubmitObject($event)"
                            :index-id-value="1"
                            :radio-checked="true"
        />
      </div>
      <div class="mt-2" style="border: 1px solid black;">
        <h5 style="color: black">Users</h5>
        <div>
          <h6 style="color: black">District Admins</h6>
          <UserHierarchyList :id-organization="idOrganizationOne" :role-hierarchy="3" :org-type="4"/>
        </div>
        <div>
          <h6 style="color: black">Supervisors</h6>
          <UserHierarchyList :id="idOrganizationOne" :role-hierarchy="4" :org-type="4"/>
        </div>
        <div>
          <h6 style="color: black">Students (Supervisor Course)</h6>
          <UserHierarchyList :id-organization="idOrganizationOne" :role-hierarchy="6" :org-type="4"/>
        </div>
      </div>

    </div>
    <div class="card" style="border: 3px solid red;">
      <div class="card-header">
        <h3 style="color: black">Removing</h3>
      </div>
      <div class="card-body" >
        <!--        <h4>{{ removingOrg.organizationName.value }}</h4>-->
        <MergeObjectDisplay :display-data="removingOrg"
                            @valChanged="updateSubmitObject($event)"
                            :index-id-value="2"
                            :radio-checked="false"
        />
      </div>
      <div class="mt-2" style="border: 1px solid black;">

        <h5 style="color: black">Users to be Merged</h5>
        <div>
          <h6 style="color: black">District Admins</h6>
          <UserHierarchyList :id-organization="idOrganizationTwo" :role-hierarchy="3" :org-type="4"/>
        </div>
        <div>
          <h6 style="color: black">Supervisors</h6>
          <UserHierarchyList :id="idOrganizationTwo" :role-hierarchy="4" :org-type="4"/>
        </div>
        <div>
          <h6 style="color: black">Students (Para Course)</h6>
          <UserHierarchyList :id-organization="idOrganizationTwo" :role-hierarchy="5" :org-type="4"/>
        </div>
        <div>
          <h6 style="color: black">Students (Supervisor Course)</h6>
          <UserHierarchyList :id-organization="idOrganizationTwo" :role-hierarchy="6" :org-type="4"/>
        </div>
      </div>
    </div>
    <div class="card" style="border: 3px solid grey;">
      <div class="card-header">
        <h3>Result Preview</h3>
      </div>
      <div class="card-body">
        <!--        <h4>Keeping Values</h4>-->
        <table style="margin: 10px; table-layout: fixed;">
          <thead>
          <tr>
            <th style="color: black">Field Name</th>
            <th style="color: black">Content</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(val, key) in orgToKeep" :key="key">
            <td v-if="!key.includes('id') && !key.includes('Code')" style="color: black">{{ keys[key] }}</td>
            <td v-if="!key.includes('id') && !key.includes('Code')" style="color: black">{{ val }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <!--    <div class="col" v-for="organization in organizations" :key="organization">-->
    <!--      <div class="card mt-2"-->
    <!--           :style="organization.idToKeep === selectedOrganizationId ? 'border: 3px solid green;' : 'border: 3px solid red;'">-->
    <!--        <OrganizationSingleDisplay :organization-data="organization"-->
    <!--                                   @selectedOrganization="selectedOrganization(organization.idToKeep, organization.idToRemove)"/>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div>
    <button class="button btn-primary" type="submit" @click="showModal = true">Merge</button>
  </div>
  <div v-if="showModal">
    <Teleport to="body">
      <OrgMergeModal
          :org-data="orgToKeep"
          :org-type="`Organization`"
          :show="showModal"
          @close="showModal = false"
          @mergeCall="mergeHelper"/>
    </Teleport>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from 'vue';
import Swal from "sweetalert2";
import {API_URL} from "../../../../Constants";
import axios from 'axios';
import {fromPairs} from "lodash/array";
import {toPairs} from "lodash/object";
import {isEmpty, isEqual} from "lodash/lang";
import {useStore} from "vuex";
import MergeObjectDisplay from "@/components/Merge/MergeObjectDisplay";
import OutstateHierarchy from "@/components/UserOrganizationHierarchy/OutstateHierarchy/OutstateHierarchy";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList";
import router from "@/router";
import OrgMergeModal from "@/components/Merge/OrgMergeModal.vue";

export default {
  name: "OutstateOrganizationMerge",
  components: {UserHierarchyList, MergeObjectDisplay, OrgMergeModal},
  props: {
    idOrganizationOne: Number,
    idOrganizationTwo: Number
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const selectedOrganizationId = ref(-1);
    const removeOrganizationId = ref(-1);
    const keepingOrgDisplay = ref({});
    const removingOrg = ref({});
    const orgToKeep = ref({});
    const keys = ref([]);
    const showModal = ref(false);

    // will need to add a request body for the organization

    onBeforeMount(() => {
      getOrganizations();
    })

    watch(() => props.idOrganizationOne, () => {
      if (props.idOrganizationTwo !== -1) getOrganizations();
    })

    watch(() => props.idOrganizationTwo, () => {
      if (props.idOrganizationOne !== -1) getOrganizations();
    })

    async function getOrganizations() {
      keepingOrgDisplay.value = {};
      removingOrg.value = {};
      orgToKeep.value = {};
      selectedOrganizationId.value = -1;
      removeOrganizationId.value = -1;
      let get_uri = API_URL + "/outNeOrganization/displayById?_idOrganization=";
      let org_one_tmp;
      await axios.get(get_uri + props.idOrganizationOne)
          .then((result) => {
            org_one_tmp = result.data
            orgToKeep.value = result.data;
          });

      let org_two_tmp;
      await axios.get(get_uri + props.idOrganizationTwo)
          .then((result) => {
            org_two_tmp = result.data
          });


      let diff_keys = Object.keys(org_one_tmp).filter(k => org_one_tmp[k] !== org_two_tmp[k]);

      let org_one = {};

      for (const prop in org_one_tmp) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");

        let add_val = !prop.includes('id') && !prop.includes('Code');
        if (add_val) {
          org_one[prop] = {
            value: org_one_tmp[prop],
            highlight: diff_keys.includes(prop),
            label: result
          }
        }
      }

      let org_two = {}

      for (const prop in org_two_tmp) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");

        let add_val = !prop.includes('id') && !prop.includes('Code');
        if (add_val) {
          org_two[prop] = {
            value: org_two_tmp[prop],
            highlight: diff_keys.includes(prop),
            label: result
          }
          keys.value[prop] = result;
        }
      }

      keepingOrgDisplay.value = org_one;
      removingOrg.value = org_two;

      selectedOrganizationId.value = props.idOrganizationOne;
      removeOrganizationId.value = props.idOrganizationTwo;
    }


    function mergeHelper() {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'This cannot be undone. Users will not be able to register under the old school.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      }).then((res) => {
        if (res.isConfirmed) {
          commitMerge();
        }
      })
    }

    async function commitMerge() {
      let put_uri = `${API_URL}/outNeOrganization/merge/keeping/${selectedOrganizationId.value}/removing/${removeOrganizationId.value}`;
      await axios.put(put_uri, orgToKeep.value, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: 'Successfully Merged',
            icon: 'success'
          }).finally(() => {
            router.push({name: 'ViewOutstate', params: {idOrganization: selectedOrganizationId.value}})
          })
        }
      })
    }

    const showOrgs = computed(() => {
      return !isEmpty(keepingOrgDisplay.value) && !isEmpty(removingOrg.value);
    })

    function updateSubmitObject(event) {
      orgToKeep.value[event.key] = event.value;
    }

    return {
      selectedOrganizationId,
      keepingOrgDisplay,
      removingOrg,
      showOrgs,
      orgToKeep,
      keys,
      showModal,
      mergeHelper,
      updateSubmitObject
    }
  }
}
</script>

<style scoped>
th,
td {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  /*height: 50px;*/
  padding-top: 0;
  width: 50%;
  border: 1px solid black;
  color: black;
}

th {
  border: 1px solid black;
  color: black;
}

.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  /*max-height: 800px;*/
  /*!*height: 250px;*!*/
  /*width: 250px;*/
  /*border-radius: 0;*/
  padding: 0;
  /*border: 1px solid rgb(0, 0, 0);*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*padding: 1rem 1rem;*/

}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}

</style>