<template>
  <div class="col-sm">
    <label>State</label>
    <select class="form-select" v-model="selectedState" @change="changeState($event)" :disabled="selectDisabled" style="height: 41px;">
      <option :value="-1">Select a State</option>
      <option v-for="state in states" :key="state.idState" :value="state.stateShort">{{ state.stateName }}</option>
    </select>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch} from "vue";
import axios from "axios";
import {statesWithOrgs} from "@/Helpers/ApiCalls/OutStateOrgAPICalls";

export default {
  name: "ParticipatingStates",
  props: {
    stateCode: String,
    selectDisabled: Boolean
  },
  setup(props, context) {
    const states = ref([]);
    const selectedState = ref("-1");

    onBeforeMount(() => {
      getStates();
      if (props.stateCode) selectedState.value = props.stateCode;
    })

    async function getStates() {
      await axios.get(statesWithOrgs())
          .then((result) => {
            states.value = result.data.sort((a, b) => a.stateName > b.stateName ? 1 : -1);
          })
    }

    function changeState(event) {
      context.emit("selectedState", event.target.value);
      stateName(event.target.value);
    }

    function stateName(stateShort) {
      const state = states.value.find((fullState) => fullState.stateShort === stateShort);
      context.emit("stateName", state.stateName)
    }

    watch(() => props.stateCode, () => {
      selectedState.value = props.stateCode;
    })

    return {
      states,
      selectedState,
      changeState
    }
  }
}
</script>

<style scoped>

</style>