<template>
  <div class="col-sm">
    <label>Country</label>
    <select class="form-select" v-model="selectedCountry" @change="updateCountry($event)" :disabled="selectDisabled" style="height: 41px;">
      <option :value="'-1'">Select a Country</option>
      <option :value="'ALL'" v-if="showAllSelectValue">All Participant Growth Data</option>
      <option v-for="country in countries" :key="country.idCountry" :value="country.countryCode">
        {{ country.countryName }}
      </option>
    </select>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch} from "vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";
import {countriesWithOrgs} from "@/Helpers/ApiCalls/OutStateOrgAPICalls";

export default {
  name: "ParticipatingCountries",
  props: {
    showAllSelectValue: Boolean,
    selectDisabled: Boolean,
    defaultCountry: String
  },
  setup(props, context) {
    const countries = ref([]);
    const selectedCountry = ref("-1");

    onBeforeMount(() => {
      getCountries();
      if (props.defaultCountry) {
        selectedCountry.value = props.defaultCountry;
      }
    })

    async function getCountries() {
      await axios.get(countriesWithOrgs())
          .then((result) => {
            countries.value = result.data.sort((a, b) => a.countryName > b.countryName ? 1 : -1);
          })
    }

    function updateCountry(event) {
      context.emit("countryCode", event.target.value);
      if (event.target.value !== 'ALL') countryName(event.target.value);
    }

    function countryName(countryCode) {
      const country = countries.value.find((c) => c.countryCode === countryCode);
      context.emit("countryName", country.countryName)
    }

    watch(() => props.defaultCountry, () => {
      selectedCountry.value = props.defaultCountry;
    })

    return {
      countries,
      updateCountry,
      selectedCountry
    }

  }
}
</script>

<style scoped>

</style>