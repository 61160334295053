<template>
  <div class="mt-2">
    <h3>
      {{ organization.organizationName }}
    </h3>
  </div>

  <div class="row mt-2">
    <div class="col-sm"></div>

    <div class="col-sm">
      <div class="accordion mt-2">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed"
                  :id="'district-admins-header' + idOrganization"
                  aria-expanded="false"
                  :aria-controls="'district-admins-' + idOrganization"
                  data-toggle="collapse"
                  :data-target="'#district-admins-' + idOrganization">District Admins
          </button>
        </h2>
        <div :id="'district-admins-' + idOrganization" class="accordion-collapse collapse"
             :aria-labelledby="'district-admins-header' + idOrganization">
          <UserHierarchyList
              :org-type="4"
              :id-organization="idOrganization"
              :role-hierarchy="3"/>
        </div>
      </div>
    </div>

    <div class="col-sm">
      <div>
        <div class="accordion mt-2">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed"
                    :id="'instructors-header' + idOrganization"
                    aria-expanded="false"
                    :aria-controls="'instructors-' + idOrganization"
                    data-toggle="collapse"
                    :data-target="'#instructors-' + idOrganization">Supervisors
            </button>
          </h2>
          <div :id="'instructors-' + idOrganization" class="accordion-collapse collapse"
               :aria-labelledby="'instructors-header' + idOrganization">
            <UserHierarchyList
                :org-type="4"
                :id-organization="idOrganization"
                :role-hierarchy="4"/>
          </div>
        </div>
      </div>

      <div>
        <div class="accordion mt-2">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed"
                    :id="'paras-header' + idOrganization"
                    aria-expanded="false"
                    :aria-controls="'paras-' + idOrganization"
                    data-toggle="collapse"
                    :data-target="'#paras-' + idOrganization">Students (Para Course)
            </button>
          </h2>
          <div :id="'paras-' + idOrganization" class="accordion-collapse collapse"
               :aria-labelledby="'paras-header' + idOrganization">
            <UserHierarchyList
                :org-type="4"
                :id-organization="idOrganization"
                :role-hierarchy="5"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ref, onBeforeMount, watch} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList";

export default {
  name: "OutstateHierarchy",
  components: {UserHierarchyList},
  props: {
    idOrganization: Number
  },
  setup(props) {
    const organization = ref({});

    onBeforeMount(() => {
      getOrganization();
    })

    watch(() => props.idOrganization, () => {
      getOrganization();
    })

    async function getOrganization() {
      let get_uri = API_URL + "/outNeOrganization/displayById?_idOrganization=" + props.idOrganization;
      await axios.get(get_uri)
          .then((result) => {
            organization.value = result.data;
          })
    }

    return {
      organization
    }
  }
}
</script>

<style scoped>
.accordion-header {
  border: 1px solid black;
}
</style>