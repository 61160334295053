<template>
  <div>
    <h2>Organization Merge</h2>
  </div>
  <div class="row">
    <div class="col-sm-4">

      <ParticipatingCountries
          @countryCode="changeCountry($event)"
          :show-all-select-value="false"
      />
    </div>
    <div v-if="showState" class="col-sm-4">
      <ParticipatingStates
          @selectedState="changeState($event)"
      />

    </div>

    <div v-if="showOrgDropdowns" class="row">
      <div class="col-sm">
        <label>First Organization</label>
        <select class="form-select" v-model="idOrgOne" @change="changeOrganization($event, idOrgOne)">
          <option :value="-1">Select an Organization</option>
          <option v-for="organization in orgOneFilteredList" :key="organization"
                  :value="organization.idOutStateOrganization">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
      <div class="col-sm">
        <label>Second Organization</label>
        <select class="form-select" v-model="idOrgTwo" @change="changeOrganization($event, idOrgTwo)">
          <option :value="-1">Select an Organization</option>
          <option v-for="organization in orgTwoFilteredList" :key="organization"
                  :value="organization.idOutStateOrganization">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="showOrganizationData">
    <OutstateOrganizationMerge
        :id-organization-one="idOrgOne"
        :id-organization-two="idOrgTwo"/>
  </div>
</template>

<script>
import {computed, ref} from 'vue';
import axios from "axios";
import OutstateOrganizationMerge from "@/components/Merge/Outstate/OutstateOrganizationMerge";
import {API_URL} from "../../../../../Constants";
import ParticipatingStates from "@/components/Reporting/OrganizationDropdowns/ParticipatingStates";
import ParticipatingCountries from "@/components/Reporting/OrganizationDropdowns/ParticipatingCountries";

export default {
  name: "OutstateOrganizationMergeDisplay",
  components: {ParticipatingCountries, ParticipatingStates, OutstateOrganizationMerge},
  setup() {
    const idOrgOne = ref(-1);
    const idOrgTwo = ref(-1);
    const selectedCountry = ref("-1");
    const selectedState = ref("0");
    const organizations = ref([]);

    const showState = computed(() => {
      return selectedCountry.value === 'US';
    })

    const orgOneFilteredList = computed(() => {
      if (idOrgTwo.value !== -1) {
        return organizations.value.filter(organization => organization.idOutStateOrganization !== idOrgTwo.value);
      }
      return organizations.value;
    })

    const orgTwoFilteredList = computed(() => {
      if (idOrgOne.value !== -1) {
        return organizations.value.filter(organization => organization.idOutStateOrganization !== idOrgOne.value);
      }
      return organizations.value;
    })

    const showOrgDropdowns = computed(() => {
      return organizations.value.length > 1;
    })

    const showOrganizationData = computed(() => {
      return idOrgOne.value !== -1 && idOrgTwo.value !== -1;
    })

    function changeCountry(event) {
      selectedCountry.value = event;
      getOrganizations();
    }

    function changeState(event) {
      selectedState.value = event;
      getOrganizations();
    }

    function changeOrganization(event, idValue) {
      idValue = event.target.value;
    }

    async function getOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/";

      if (!showState.value) {
        get_uri += "getByCountry?_countryCode=" + selectedCountry.value;
      } else if (selectedState.value !== '0' && selectedState.value !== 'NE') {
        get_uri += "getByState?_stateShort=" + selectedState.value;
      }

      await axios.get(get_uri)
          .then((result) => {
            organizations.value = result.data;
          }).finally(() => {
            idOrgOne.value = -1;
            idOrgTwo.value = -1;
          })
    }

    return {
      idOrgOne,
      idOrgTwo,
      selectedState,
      selectedCountry,
      showState,
      orgOneFilteredList,
      orgTwoFilteredList,
      showOrgDropdowns,
      showOrganizationData,
      organizations,
      changeOrganization,
      changeCountry,
      changeState
    }
  }
}
</script>

<style scoped>

</style>